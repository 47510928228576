import React from "react";
import {
  BannerPage,
  ButtonPrimary,
  ContainerContentFixed,
  ContainerCta,
  InternalLink,
  InternalLinkTypes,
  Layout,
  SEO,
} from "../components";
import { Row, Col } from "antd";

const Gallery = () => {
  return (
    <Layout>
      <SEO
        title="Gallery"
        description={"Browse through our photo/video gallery"}
      />
      <BannerPage
        title={"Gallery"}
        subTitle={"Browse through our photo/video gallery"}
      />
      <ContainerContentFixed style={{ textAlign: "center" }}>
        <Row gutter={[8, 8]}>
          <Col md={12} xs={24}>
            <ButtonPrimary>
              <InternalLink url={InternalLinkTypes.VideosGallery}>
                Videos Gallery
              </InternalLink>
            </ButtonPrimary>
          </Col>
          <Col md={12} xs={24}>
            <ButtonPrimary>
              <InternalLink url={InternalLinkTypes.PhotosGallery}>
                Photos Gallery
              </InternalLink>
            </ButtonPrimary>
          </Col>
        </Row>
        <section style={{ textAlign: "center" }}>
          <InternalLink url={InternalLinkTypes.Disclaimer}>
            See our disclaimer
          </InternalLink>
        </section>
      </ContainerContentFixed>

      <ContainerCta
        title={"Need more information?"}
        secondaryLink={InternalLinkTypes.Contact}
        secondaryText={"Contact Our Team"}
      />
    </Layout>
  );
};

export default Gallery;
